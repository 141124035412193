/* eslint-disable react/no-deprecated */
import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Layout } from "./utility/context/Layout";
import * as serviceWorker from "./serviceWorker";
import { store } from "./redux/storeConfig/store";
import Spinner from "./components/@vuexy/spinner/Fallback-spinner";
//import 'antd/dist/antd.css'
import "./assets/fontawesome/all.min.css";
import "./index.scss";
import { saveState } from "./redux/storeConfig/localStorage";

const LazyApp = lazy(() => import("./App"));

store.subscribe(() => {
  saveState(store.getState());
});
ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<Spinner />}>
      <Layout>
        <LazyApp />
      </Layout>
    </Suspense>
  </Provider>,
  document.getElementById("root")
);
serviceWorker.unregister();
