import { api } from "./common";

/* IDENTIFICATION */

export const loginUser = (data) => api.post("/authenticator/admin", { data });

export const authUserGet = (uuid, xAccess) =>
  api.get(`/admin/${uuid}`, { xAccess });

/* ADMINISTRATEURS */

export const adminsGet = (xAccess, archive) =>
  api.get(`/admins?archive=${archive}`, { xAccess });

export const adminGet = (uuid, xAccess) =>
  api.get(`/admin/${uuid}`, { xAccess });

export const adminPost = (xAccess, data) =>
  api.post("/admin", { xAccess, data });

export const adminPut = (uuid, xAccess, data) =>
  api.put(`/admin/${uuid}`, { xAccess, data });

export const adminDel = (uuid, xAccess) =>
  api.delete(`/admin/${uuid}`, { xAccess });

export const sendMailGet = (uuid, xAccess) =>
  api.get(`/admin/sendMail/${uuid}`, { xAccess });
