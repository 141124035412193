import { combineReducers } from "redux";
import customizer from "./customizer/";
import auth from "./auth/";
import navbar from "./navbar/Index";
import login from "./auth/login.reducer";
import authUser from "./auth/user.reducer";
import admins from "./admins/admins.reducer";

const rootReducer = combineReducers({
  customizer: customizer,
  auth: auth,
  navbar: navbar,
  login: login,
  authUser: authUser,
  admins: admins,
});

export default rootReducer;
