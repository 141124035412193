import React from "react";
import * as Icon from "react-feather";

export const myConfig = () => {
  const navigationConfig = [
    {
      id: "home",
      title: "Tableau de bord",
      type: "item",
      icon: <Icon.Home size={20} />,
      permissions: ["admin", "editor"],
      navLink: "/",
    },
    {
      /*
      type: "groupHeader",
      groupTitle: "Opérations",
    },
    {
      id: "ope",
      title: "Opérations",
      type: "item",
      icon: <Icon.Hash size={20} />,
      permissions: ["admin", "editor"],
      navLink: "/operations",
    },
    {
      id: "vaccin",
      title: "Vaccination",
      type: "item",
      icon: <i className="fa-regular fa-syringe mr-1 font-20" />,
      permissions: ["admin", "editor"],
      navLink: "/vaccination",
    },
    {
      id: "demat",
      title: "e-Signature",
      type: "item",
      icon: <i className="fa-regular fa-file-pen mr-1 font-20" />,
      permissions: ["admin", "editor"],
      navLink: "/demat",
    },
    {
      id: "formation",
      title: "Form-UPP",
      type: "item",
      icon: <i className="fa-regular fa-graduation-cap mr-1 font-20" />,
      permissions: ["admin", "editor"],
      navLink: "/formations",
    },
    {
      type: "groupHeader",
      groupTitle: "Officines & Adhérents",
    },
    {
      id: "officines",
      title: "Officines",
      type: "collapse",
      icon: <i className="fa-regular fa-house-medical mr-1 font-20" />,
      children: [
        {
          id: "offactives",
          title: "Actives",
          type: "item",
          icon: <Icon.Circle size={12} />,
          permissions: ["admin", "editor"],
          navLink: "/officines",
        },
        {
          id: "offarchive",
          title: "Archivées",
          type: "item",
          icon: <Icon.Circle size={12} />,
          permissions: ["admin", "editor"],
          navLink: "/officines/archives",
        },
      ],
    },
    {
      type: "groupHeader",
      groupTitle: "Services",
    },
    {
      id: "comlabo",
      title: "Com Labo",
      type: "item",
      icon: <Icon.Radio size={20} />,
      permissions: ["admin"],
      navLink: "/comlabo",
    },
    {
      type: "groupHeader",
      groupTitle: "Paramètres",
    },
    {
      id: "parametres",
      title: "Paramètres",
      type: "collapse",
      icon: <Icon.Settings size={20} />,
      permissions: ["admin", "editor"],
      children: [
        {
          id: "secteurs",
          title: "Secteurs",
          type: "item",
          icon: <Icon.Circle size={12} />,
          permissions: ["admin", "editor"],
          navLink: "/parametres/secteurs",
        },
        {
          id: "import",
          title: "Import",
          type: "item",
          icon: <Icon.Circle size={12} />,
          permissions: ["admin", "editor"],
          navLink: "/import",
        },
        {
          id: "synchro",
          title: "Synchro",
          type: "item",
          icon: <Icon.Circle size={12} />,
          permissions: ["admin", "editor"],
          navLink: "/synchro",
        },
      ],
    */
    },
    {
      type: "groupHeader",
      groupTitle: "Utilisateurs",
    },
    {
      id: "admin",
      title: "Utilisateurs",
      type: "item",
      icon: <Icon.Users size={20} />,
      permissions: ["admin"],
      navLink: "/utilisateurs",
    },
  ];
  return navigationConfig;
};
