import axios from "axios";
import { Configs } from "../../configs/general.config";

async function callApi(endpoint, options) {
  try {
    return await axios({
      ...options,
      url: `${Configs.urlApi}/api/${Configs.typeApi}/v1${endpoint}`,
      headers: {
        Authorization: `Bearer ${Configs.BearerToken}`,
        "x-access-token": options.xAccess,
      },
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function api(endpoint, options) {
  return callApi(endpoint, options);
}

api.get = (endpoint, options) =>
  callApi(endpoint, { method: "GET", ...options });
api.post = (endpoint, options) =>
  callApi(endpoint, { method: "POST", ...options });
api.put = (endpoint, options) =>
  callApi(endpoint, { method: "PUT", ...options });
api.patch = (endpoint, options) =>
  callApi(endpoint, { method: "PATCH", ...options });
api.delete = (endpoint, options) =>
  callApi(endpoint, { method: "DELETE", ...options });
