import { fork } from "redux-saga/effects";

import loginUserSagas from "./auth/login.saga";
import authUserSagas from "./auth/user.saga";
import adminsSagas from "./admins/admins.saga";

function* rootSaga() {
  yield fork(loginUserSagas);
  yield fork(authUserSagas);
  yield fork(adminsSagas);
}

export default rootSaga;
