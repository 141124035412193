export const Configs = {
  BearerToken:
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJvcmdhbml6YXRpb24iOiJEYWlseVNvZnQiLCJwaG9uZSI6IjA2ODgxMzc4NDAiLCJlbWFpbCI6InNlYmFzdGllbi5hbmdvdEBkYWlseS1zb2Z0LmNoIiwicndkIjowLCJpYXQiOjE2OTk4OTI2ODF9.RUxF2RxatqWvYlBUkbcqaFUfOT1xKUlMiIbROGseDu0",
  urlApi: "https://api.daily-soft.ch",
  typeApi: "production",
  GOOGLE_KEY: "",
  userRole: [
    {
      key: 1,
      value: "Utilisateur",
    },
    {
      key: 2,
      value: "Administrateur",
    },
    {
      key: 3,
      value: "Super Administrateur",
    },
  ],
};
